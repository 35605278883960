@import "Utilities";

.AboutUs{
    width: 100%;
    @include FlexColumn;
    position: relative;

}
.NavAbout{
    width: 100%;
    min-height: 80vh;
    @include FlexCenter;
    position: relative;
    background:  url(../img/DJI_0104.jpg) no-repeat center center/cover;
    position: relative;

    .shadow{
        width: 100%;
        height: 100%;
        background-color: #53535364;
        @include FlexCenter;
        position: absolute;

        a{
            color: #fff;
            text-decoration: none;
            font-size: 40px;
            margin-top: 5rem;
        }
        pre {
            color: #fff;
            font-size: 40px;
            margin: 0 20px;
            margin-top: 5rem;

        }
    }
}

.Description{
    width: 100%;
    @include FlexColumn;
    padding: 4% 6%;
    position: relative;
    p{
        margin-top: 2rem;
        text-align: justify;
        font-size: 20px;
        line-height: 45px;
        margin-bottom: 4rem;
    }

    
}

.Products{
    width: 100%;
    @include FlexCenter;
    justify-content: space-between;

    .Icons{
        width: 40%;
        height: 45rem;
        @include FlexColumn;
        justify-content: center;
        .items{
            width: 100%;
            height: 8rem;
            margin: 20px 0;
            border-left: 1px solid #00229A;
            @include FlexColumn;
            align-items: self-start;
            padding:0 2rem;
            img{
                width: 2.5rem;
            }
            h2{
                margin-top: 1rem;
                width: 60%;
                text-align: left;
                font-size: 20px;
                line-height: 40px;
                color:  #002BC1;
            }
        }
    }
    .PhotoPlace{
        width: 60%;
        height: 50rem;
        @include FlexCenter;
        justify-content: left;
        position: relative;
        img:nth-child(2){
            position: absolute;
            bottom: 0;
            right: 25%;
        }
        img:nth-child(3){
            position: absolute;
            bottom: 100px;
            right: 25%;
        }
        img:nth-child(4){
            position: absolute;
            top: 0;
        }
        .mainBox{
            width: 90%;
            height: 90%;
            position: relative;
            @include FlexCenter;
            .ColorBox{
                width: 70%;
                height:90%;
                background-color: #02005E;
                position: absolute;
                left: 0;
                img {
                    width: 85%;
                    position: inherit;
                    bottom: 0;
                    left: 25%;
                    z-index: 5;
                }
            }

        }
    }
}


/* /////////////////////////////////////////////////responsive////////////////////////////////////////////////// */
@media (max-width: 750px) {
    .NavAbout{
        .shadow{
            a{
                font-size: 25px;
            }
            pre {
                font-size: 25px;   
            }
        }
    }
    .Description{
        h1{
            font-size: 24px;
            margin-top: 3rem;
        }
        p{
            margin-top: 2rem;
            text-align: justify;
            font-size: 17px;
            line-height: 30px;
            margin-bottom: 7rem;
        }
       
        
    }

    .Products{
        flex-direction: column;
    
        .Icons{
            width: 100%;
            height: auto;
            margin-top: 2rem;
            .items{
                width: 90%;
                border-left: none;
                height: 9rem;
                align-items: center;
                border-bottom: 1px solid #002BC1;
                img{
                    width: 2.5rem;
                }
                h2{
                    margin-top: 1rem;
                    width: 90%;
                    text-align: center;
                    font-size: 17px;
                    line-height: 40px;
                    color:  #002BC1;
                }
            }
        }
        .PhotoPlace{
            width: 100%;
            height: 40rem;
            @include FlexCenter;
            .mainBox{
                width: 100%;
                .ColorBox{
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    align-items: center;
                    img {
                        width: 85%;
                        left: 0;
                        bottom: 0;
                        position: relative;
                    }
                }
    
            }
        }
    }
    
    


}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("./fonts/Inter.ttf") format("truetype");
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#App {
  text-align: center;
}
#App .container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  font-family: 'Inter';
  overflow: hidden;
}
